/*
 * @Author: Chen Zhi
 * @Date: 2024-06-14 11:52:39
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2025-02-11 18:23:44
 * @Description: 路由模块
 */
import { lazy } from "react"
import { Navigate } from "react-router-dom"

// 快速导入工具函数
const lazyLoad = (moduleName: string) => {
  const Module = lazy(() => import(`@/views/${moduleName}`))
  return <Module />
}
// 路由鉴权组件
const Appraisal = ({ children }: any) => {
  const token = localStorage.getItem("token")
  return token ? children : <Navigate to="/login" />
}

interface Router {
  name?: string
  path: string
  children?: Array<Router>
  element?: any
}

// 主体路径对象
const mainObj: any = {
    // 一级路径：['二级路径'，'二级路径/三级路径']
    system: ['role', 'menu'],
    users: ['user', 'player', 'write-log'],
    activity: ['active', 'active/gift', 'active/gift/product'],
    game:['email/normal', 'email/system', 'notice', 'room', 'suggest', 'check/nick', 'room'],
    mall: ['shop', 'item', 'record/recharge', 'record/grant', 'grant/diamond', 'grant/gem', 'grant/gold'],
    reward: ['exchange'],
    stat: ['player/login-active', 'player/level', 'player/lv', 'player/vip', 'player/online', 'cost/recharge', 'cost/currency', 'cost/ltv', 'other/channel']
}

/**
 * @description: 获取子路由对象
 * @param {object} obj
 * @return {*} 子路由对象
 */
const getChildrenFunc = (key: string, obj: any): any => {
    const arr: Array<Router> = [
      {
        path: "",
        element: lazyLoad("main/home"),
      },
      {
        path: "home",
        element: lazyLoad("main/home"),
      }
    ]
    for(let o in obj) {
      obj[o].map((m: string) => {
        const path = o + '/' + m
        const item = {
            path,
            element: lazyLoad(`${key}/${path}`)
        }
        arr.push(item)
        return m
      })
    }
    return arr
}

const routes: Array<Router> = [
    {
      path: "/login",
      element: lazyLoad("login"),
    },
    {
      path: "/smsLogin",
      element: lazyLoad("smsLogin"),
    },
    {
      path: "/",
      element: <Appraisal>{lazyLoad("main")}</Appraisal>,
      children: getChildrenFunc("main", mainObj)
    },
    {
      path: "*",
      element: lazyLoad("other/not-found"),
    },
]

export default routes